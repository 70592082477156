export enum PERMISSION_LEVEL {
  READ = 'READ',
  WRITE = 'WRITE',
  NONE = 'NONE',
}

export enum PERMISSION {
  ABSENCE = 'ABSENCE',
  ACCOUNT = 'ACCOUNT',
  ADVANCED_ANALYTICS_COMPLIANCE = 'ADVANCED_ANALYTICS_COMPLIANCE',
  ADVANCED_ANALYTICS_ENGAGEMENT = 'ADVANCED_ANALYTICS_ENGAGEMENT',
  ADVANCED_ANALYTICS_INTERNAL_DEVELOPMENT = 'ADVANCED_ANALYTICS_INTERNAL_DEVELOPMENT',
  ADVANCED_ANALYTICS_OPTIMISATION = 'ADVANCED_ANALYTICS_OPTIMISATION',
  APPLY_SHIFT_TYPE_RULES = 'APPLY_SHIFT_TYPE_RULES',
  ATTACHMENTS = 'ATTACHMENTS',
  CREATE_PULSE = 'CREATE_PULSE',
  FORECAST = 'FORECAST',
  FORECAST_BETA = 'FORECAST_BETA',
  FORECAST_OVERVIEW = 'FORECAST_OVERVIEW',
  DEMAND_FORECASTING = 'DEMAND_FORECASTING',
  INITIATE_E_SIGNING = 'INITIATE_E_SIGNING',
  LOCK_SCHEDULING = 'LOCK_SCHEDULING',
  MANUAL_SALARY_TYPE = 'MANUAL_SALARY_TYPE',
  PEOPLE = 'PEOPLE',
  PEOPLE_DETAILS = 'PEOPLE_DETAILS',
  PEOPLE_AGREEMENTS = 'PEOPLE_AGREEMENTS',
  PREVIEW_PDF = 'PREVIEW_PDF',
  PUNCHES = 'PUNCHES',
  QMAIL = 'QMAIL',
  SALARY = 'SALARY',
  SETTINGS = 'SETTINGS',
  SCHEDULING = 'SCHEDULING',
  SHIFT_TYPES = 'SHIFT_TYPES',
  TAGS = 'TAGS',
  TIME_TRACKER = 'TIME_TRACKER',
  TASK_MANAGEMENT = 'TASK_MANAGEMENT',
  TASK_MANAGEMENT_DISTRIBUTION = 'TASK_MANAGEMENT_DISTRIBUTION',
  TASK_MANAGEMENT_EVENTS = 'TASK_MANAGEMENT_EVENTS',
  TASK_MANAGEMENT_FILES = 'TASK_MANAGEMENT_FILES',
  TASK_MANAGEMENT_FORMS = 'TASK_MANAGEMENT_FORMS',
  TASK_MANAGEMENT_HOME = 'TASK_MANAGEMENT_HOME',
  TASK_MANAGEMENT_REPORTS = 'TASK_MANAGEMENT_REPORTS',
  TASK_MANAGEMENT_STORIES = 'TASK_MANAGEMENT_STORIES',
  TASK_MANAGEMENT_TASKS = 'TASK_MANAGEMENT_TASKS',
  TASK_MANAGEMENT_WIDGETS = 'TASK_MANAGEMENT_WIDGETS',
  QCLOCK = 'QCLOCK',
  AI = 'AI',
  TEAMS = 'TEAMS',
  SECRETS = 'SECRETS',
  DATA = 'DATA',
  NOTIFICATIONS = 'NOTIFICATIONS',
  RELEASES = 'RELEASES',
  ALGORITHMS = 'ALGORITHMS',
  ALGORITHM_INVOCATION = 'ALGORITHM_INVOCATION',
  ALGORITHM_TRAINING = 'ALGORITHM_TRAINING',
  INTEGRATIONS = 'INTEGRATIONS',
  INTEGRATION_INVOCATION = 'INTEGRATION_INVOCATION',
  TRANSFER_TO_PAYROLL = 'TRANSFER_TO_PAYROLL',
  VIEW_E_SIGNED_DOCUMENTS = 'VIEW_E_SIGNED_DOCUMENTS',
  SCHEDULE_AVAILABILITY = 'SCHEDULE_AVAILABILITY',
  INTEGRATION_CREDENTIALS = 'INTEGRATION_CREDENTIALS',
  NEWSFEED = 'NEWSFEED',
  OPENING_HOURS = 'OPENING_HOURS',
  OPTIMIZATION_SETTINGS = 'OPTIMIZATION_SETTINGS',
  MESSENGER = 'MESSENGER',
  MESSENGER_PLUS = 'MESSENGER_PLUS',
  MESSENGER_PRO = 'MESSENGER_PRO',
  MESSENGER_MODERATION = 'MESSENGER_MODERATION',
}

// Updated based on what returned from `useCurrentGroup`.
// Possibly incomplete as returned modules are user-specific. Keep maintaining this list.
export enum MODULE_NAME {
  ADVANCED_ANALYTICS_COMPLIANCE = 'ADVANCED_ANALYTICS_COMPLIANCE',
  ADVANCED_ANALYTICS_ENGAGEMENT = 'ADVANCED_ANALYTICS_ENGAGEMENT',
  ADVANCED_ANALYTICS_INTERNAL_DEVELOPMENT = 'ADVANCED_ANALYTICS_INTERNAL_DEVELOPMENT',
  ADVANCED_ANALYTICS_OPTIMISATION = 'ADVANCED_ANALYTICS_OPTIMISATION',
  AI = 'AI',
  CUSTOMLINKS = 'CUSTOMLINKS',
  DEMAND_FORECASTING = 'DEMAND_FORECASTING',
  FORECAST = 'FORECAST',
  FORECAST_ADVANCED_AI = 'FORECAST_ADVANCED_AI',
  FORECAST_BETA = 'FORECAST_BETA',
  MESSENGER = 'MESSENGER',
  MESSENGER_PLUS = 'MESSENGER_PLUS',
  MESSENGER_PRO = 'MESSENGER_PRO',
  QCLOCK = 'QCLOCK',
  SCHEDULE = 'SCHEDULE',
  TAGS = 'TAGS',
  TASK_MANAGEMENT = 'TASK_MANAGEMENT',
  TIME = 'TIME',
}
